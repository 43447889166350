import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          animalCareByYaraliz: "Animal Care By Yaraliz", // General Translations
          home: "Home",
          gallery: "Gallery",
          clear: "Clear",
          close: "Close",
          send: "Send",
          about: "About",
          contact: "Contact",
          contactGinger: "Contact us on Gingr",
          services: "Services",
          servicesDescription: `Our services include veterinary care, grooming, boarding, training, and other specialized services to meet the needs of pets and their owners. 
                These services are designed to promote the health and well-being of pets, while also providing owners with peace of mind and convenience.`,
          contactUs: "Contact Us",
          allRightsReserved: "All Rights Reserved.",
          contactInformation: "Contact Information",
          optional: "Optional",
          goBackHome: "Go Back Home",
          pageNotFound: "Page not found.",
          pageNotFoundDescription:
            "Please check the URL in the address bar and try again.",
          notificationSuccessTitle: "Success",
          notificationErrorTitle: "Error",
          notificationSuccessMessage: "Email was sent successfully.",
          poweredBy: "Powered by",
          next: "Next",
          previous: "Previous",
          schedule: "Schedule",
          checkInOut: "Check In/Out",
          nineToFive: "9am - 5pm",
          eightToFour: "8am - 4pm",
          nineToEleven: "9am - 11am",
          twoToFive: "2pm - 5pm",
          eightHalfToNineHalf: "8:30am - 9:30am",
          fourHalfTofiveHalf: "4:30pm - 5:30pm",
          sunday: "Sunday",
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          mondayToFriday: "Monday - Friday",
          disclaimer: "Disclaimer",
          noCheckInOutSchedule:
            "Check In/Out time is not available outside these hours.",
          noCheckInOutHolidays:
            "Check In/Out time is not available on holidays.",
          homeHeaderDescription:
            "Caring for your furry family members as if they were our own.", // Home Page Translations
          statsOne: "Daily Pets",
          statsTwo: "Yearly Pets",
          statsThree: "Satisfied Clients",
          statsFour: "Rescued Pets",
          testimonialQuote: `“Qui dolor enim consectetur do et non ex amet culpa sint in ea non dolore. Enim minim magna anim id minim eu
                cillum sunt dolore aliquip. Amet elit laborum culpa irure incididunt adipisicing culpa amet officia
                exercitation. Eu non aute velit id velit Lorem elit anim pariatur.”`,
          testimonialAuthor: "Jane Doe",
          testimonialCareer: "CEO at StaticServed",
          availableServices: "Available Services",
          changesACBY: "Changes in ACBY",
          servicesOverview: "For more details about our services, click here.",
          hotelService: "Dog Hotel",
          hotelServiceDescription: `Where your pets can enjoy comfortable and safe accommodation, balanced diet, medical care if necessary, recreational activities, lots of love and care from our specialized staff.`,
          birthdayService: "Birthday Pawtie",
          birthdayServiceDescription:
            "At our pet birthday party, we offer themed decoration, a special animal birthday cake, fun games and activities, gifts and much more so that your pet has the best birthday of his life!",
          daycareService: "Day Care",
          daycareServiceDescription:
            "We offer a complete and personalized care for your furry friend, from walks, games, balanced diet and medical care if necessary, all in a comfortable and safe environment that will allow your pet to enjoy its stay while you are away.",
          groomingService: "Grooming",
          groomingServiceDescription:
            "We provide a complete spa treatment for your furry friend, from relaxing baths, trendy haircuts, teeth cleaning and manicures, all in a friendly and safe environment that will make your pet feel at home and look like a star!",
          specializedCareService: "Special Treatment",
          meetGreetService: "Meet & Greet",
          notificationErrorMessage: "An error has occured.", // About Page Translations
          aboutDescriptionHeading: `
                Welcome to Animal Care by Yaraliz! We are a team of passionate animal caregivers who are dedicated to providing the highest level of care for your furry family members. 
                Our mission is to ensure that your pets receive the love and attention they deserve while you are away.
                `,
          aboutDescriptionOne: `
                Our company was founded by Yaraliz, an experienced and certified animal caregiver with a deep love for all animals. 
                She believes that each pet has a unique personality and needs individualized care to thrive. 
                At Animal Care by Yaraliz, we offer personalized care plans that cater to your pet's specific requirements, 
                whether it's providing medication, daily exercise, or just extra love and attention.
                Our team consists of experienced and compassionate animal caregivers who share Yaraliz's passion for animals. 
                We offer a wide range of services, including hotel for pets, grooming, and more. 
                We are available 24/7 to provide the support and care that your pets need, and we keep you updated with regular check-ins and photos.`,
          aboutDescriptionTwo: `
                At Animal Care by Yaraliz, we understand that your pets are family, and we treat them as such. 
                We strive to create a warm and welcoming environment where your pets feel comfortable and secure, and where you can trust that they are in good hands.
                We believe that building a strong relationship with our clients and their pets is essential to providing the best care possible. 
                We take the time to get to know each pet and their unique needs, and we work closely with you to ensure that we are meeting your expectations.`,
          aboutDescriptionThree: `
                Thank you for considering Animal Care by Yaraliz for your pet's care needs. 
                We look forward to meeting you and your beloved pets and providing the high-quality care they deserve.
                `,
          ceo: "CEO",
          meetYaraliz: "Meet Yaraliz Toledo",
          photographBy: "Photograph by Jane Doe",
          ceoDescriptionOne: `
                Yaraliz is the key piece of ACBY, it is the base and structure of what is known today as Animal Care by Yaraliz.
                It was that person that when no one believed in her vision, she bet everything on her with Kida's hand.`,
          ceoDescriptionTwo: `
                She is in charge of 99% of ACBY and she is that person who, no matter what situation she has or what circumstance comes her way, will always have her beautiful smile and the best to give you.
                Fear does not exist in her, that is why she always tries to give the best of herself and along with that inspires others to continue fighting for their dreams, so her goal is always achieved.`,
          ceoDescriptionThree: `
                Yaraliz has multiple preparations, but without a doubt her best preparation is the virtue she has to make everyone who comes to ACBY feel happy, and show that infinite love both to the ACBY boys and to the boys who are rescued abandoned on the streets. .
                She is that person who, no matter what time, place, or weather, always takes action to give those kids who need it a chance at life.`,
          ceoDescriptionFour: `
                What else can we say?
                Everything that Yaraliz projects in each of her videos and each of the times that she communicates with you is simply that advanced version of that visionary girl in her room imagining everything that is happening today, thanks to the support of all of you.`,
          ceoDescriptionFive: `
                We feel good that ACBY is led by a person like Yara, that her commitment and her desire to move forward with her team are what keep them prepared to always give the best version of themselves every day.
                With a lot of sweat, and with a lot of effort, with you: the pillar, the first vision, the first step to take, Yaraliz.`,
          ourTeam: "Meet the team",
          ourTeamDescription:
            "We are a dynamic group of people who are passionate about what we do and dedicated to delivering the best results for our clients.",
          kidanny: "Kidanny",
          kidannyRole: "Second Chief | Pet Sitter",
          kidannyDescription: `
                When talking about creativity and artistic mind, Kida comes out.
                Kida is the second in command in ACBY. Where he has helped make Animal Care what it is today from the beginning. From the first care kitten to today.
                He is the person behind the INTRO videos at our events, photos and ideas. He is in charge of the Gold Packages on birthdays and he has Jan with him.
                Kidanny has been involved in music since he was little and nowadays he is doing his specialty as a producer. At his young age he has achieved great goals as a professional and personally.`,
          jan: "Jan",
          janRole: "Events Pet Sitter",
          janDescription: `
                Our future vet has been working with us for two years.
                I present to you the person who, with his young age, has almost 7 years of clinical experience. He has worked with us hand in hand at the vet, with cats, dogs, birds, everything.
                He is a musician by birth and will soon be representing PR in Italy!
                Jan works with Kida in the Gold Package on our birthdays, he is in charge of doing the interaction when the puppies arrive, the one who bites the cake and who likes to have everything in order with the gifts and the leashes.`,
          luis: "Luis",
          luisRole: "Weekly & Day Care Pet Sitter",
          luisDescription: `
                He is our Pet Sitter from Tuesday to Friday and works hand in hand with Yara in the Day Cares and Pet Hotel. Every morning the boys go crazy with his arrival.
                The empathy and happiness that Luis gives us fills us with energy every day. We are super happy to have in ACBY a capable, intelligent, athletic, charismatic, responsible and loving person.`,
          galleryHeading: "For you to see", // Gallery page translations
          galleryDescription:
            "Our wonderful gallery showcases the happy and healthy pets that we have cared for with dedication and compassion.",
          contactHeading: "Get in touch", // Contact Page Translation
          contactDescription:
            "Any questions? Contact us for more information about our services.",
          summerCamp: "Summer Camp 2023", // Summer Camp Modal
          joinUs: "Join us for a summer camp on another level!",
          summerCampContact: "For more information contact us!", //Services Page Translation
          dogHotelSpecialTreatmentTitle: "Dog Hotel | Special Treatment - $50",
          dogHotelSpecialTreatmentSubTitle: "Per Pet, Per Day",
          dogHotelSpecialTreatmentTitleDescription:
            "Special Care for Unique Pups!",
          dogHotelSpecialTreatmentDescription: `This service is designed for dogs with specific needs, ensuring they receive the extra attention and care they deserve. Here are a few examples of the pups we cater to:`,
          dogHotelSpecialTreatmentUnneuteredDogsTitle: "Unneutered Dogs",
          dogHotelSpecialTreatmentUnneuteredDogsDescription:
            "If your dog isn't neutered, they may need to be separated from the general pack for safety and comfort. We provide a cozy, private space where they can relax and enjoy personalized care.",
          dogHotelSpecialTreatmentBrachycephalicDogsTitle:
            "Brachycephalic Dogs",
          dogHotelSpecialTreatmentBrachycephalicDogsDescription:
            "Breeds like Bulldogs, Pugs, and Boxers often need more rest due to their short noses and breathing difficulties. We create a calm environment with plenty of breaks and cool spaces to help them stay comfortable and happy.",
          dogHotelSpecialTreatmentSeniorDogsTitle: "Senior Dogs",
          dogHotelSpecialTreatmentSeniorDogsDescription:
            "Older dogs may require a quieter, slower pace. Our special care includes gentle activities, soft bedding, and extra attention to ensure they feel loved and secure.",
          dogHotelSpecialTreatmentShyAnxiousDogsTitle: "Shy or Anxious Dogs",
          dogHotelSpecialTreatmentShyAnxiousDogsDescription:
            "Some dogs feel overwhelmed in a pack setting. We offer a calm, stress-free space where they can feel safe and gradually adjust to their surroundings.",
          dogHotelCriticalCareTitle:
            "Dog Hotel | Specialized and Critical Care - $60",
          dogHotelCriticalCareSubTitle: "A Special Kind of LOVE",
          dogHotelCriticalCareDescription:
            "At ACBY, we provide extra tender loving care for pets with unique health needs or age-related requirements, ensuring comfort, healing, and security.",
          dogHotelCriticalCareExpertMedicalSupervisionTitle:
            "Expert Medical Supervision",
          dogHotelCriticalCareExpertMedicalSupervisionDescription:
            "Our trained caregivers, certified in critical care and dog CPR, provide personalized medical attention, administer medications, and follow vet-recommended routines to support your pet’s health journey.",
          dogHotelCriticalCareComfortableRetreatTitle: "Comfortable Retreat",
          dogHotelCriticalCareComfortableRetreatDescription:
            "Cozy, temperature-controlled rooms with plush bedding offer a calming sanctuary for recuperating pets or those enjoying their golden years.",
          dogHotelCriticalCareTailoredComfortCompanionshipTitle:
            "Tailored Comfort and Companionship",
          dogHotelCriticalCareTailoredComfortCompanionshipDescription:
            "Our skilled caregivers provide medical support, companionship, gentle play, and soothing interactions to enhance your pet's well-being.",
          dogHotelCriticalCareAlwaysWatchfulTitle: "Always Watchful",
          dogHotelCriticalCareAlwaysWatchfulDescription:
            "With 24/7 surveillance, our advanced security system ensures your pet’s safety and allows us to monitor their progress.",
          dogHotelTitle: "Dog Hotel - $40",
          dogHotelSubTitle: "Daily Per Pet",
          dogHotelDescription: `Introducing the Ultimate Dog Boarding Experience!`,
          dogHotelSubDescription: `At ACBY, we create a joyful and secure haven for your furry friend, filled with daytime fun and cozy nights.`,
          dogHotelDaytimeDelightsTitle: "Daytime Delights",
          dogHotelDaytimeDelightsDescription:
            "Your pup enjoys expansive play yards, socializing, and sunshine. Our trained caregivers ensure a safe, engaging environment.",

          dogHotelPlaytimeGaloreTitle: "Playtime Galore",
          dogHotelPlaytimeGaloreDescription:
            "From fetch to splash pools, our attentive staff provides a variety of activities tailored to your dog's preferences.",

          dogHotelDreamyNightsTitle: "Dreamy Nights",
          dogHotelDreamyNightsDescription:
            "After a fun-filled day, your dog relaxes in our comfortable, air-conditioned sleeping quarters.",

          dogHotelSecurityComfortCombinedTitle: "Security and Comfort Combined",
          dogHotelSecurityComfortCombinedDescription:
            "Our state-of-the-art security system with 24/7 surveillance ensures your pup's safety and lets you watch their joyful moments.",

          dogHotelSweetDreamsWaggingTailsTitle:
            "Sweet Dreams and Wagging Tails Await",
          dogHotelSweetDreamsWaggingTailsDescription:
            "Join delighted pet parents and enjoy worry-free travel, knowing your pup is having their own vacation.",
          dayCareTitle: "Day Care - $30",
          dayCareSubTitle: "Unleash the Ultimate Dog Adventure!",
          dayCareDescription:
            "At ACBY, we turn every pup’s dream day into reality with excitement, joy, and endless fun.",
          dayCarePlayTimeExtravaganzaTitle: "Playtime Extravaganza",
          dayCarePlayTimeExtravaganzaDescription:
            "Our spacious play areas let your dog’s energy run wild, from chasing balls to racing new friends.",
          dayCareTreatsGaloreTitle: "Treats Galore",
          dayCareTreatsGaloreDescription:
            "Delight your pup with wholesome treats that make tails wag with joy.",
          dayCareSplahZoneAdventureTitle: "Splash Zone Adventure",
          dayCareSplahZoneAdventureDescription:
            "Enjoy refreshing water play in our splash zones, keeping your dog cool and happy.",
          dayCareSocializationCentralTitle: "Socialization Central",
          dayCareSocializationCentralDescription:
            "Your dog will make friends and have a blast in our friendly, interactive environment.",
          dayCareCoolPupPopsiclesTitle: "Cool Pup Popsicles",
          dayCareCoolPupPopsiclesDescription:
            "Beat the heat with our doggy-approved frozen treats.",
          dayCareMemoriesInTheMakingTitle: "Memories in the Making",
          dayCareMemoriesInTheMakingDescription:
            "Capture adorable moments with our designated photo spots for lasting memories.",
          birthdayPawtieTitle: "Birthday Pawtie",
          birthdayBoyPawtieTitle: "Birthday Pawtie Pup - $500",
          birthdayBoyPawtieDescription: `If you want to celebrate the life of your dog and you don't have the creativity, 
                the time or the place, we take care of making that a reality. We get the decoration, the gifts for the participants and everything else.`,
          birthdaySilverPawtieTitle: "Birthday Pawtie atendee - $75",
          birthdaySilverPawtieDescription:
            "Sign up your puppy on a birthday so that he spends 8 hours playing, eating cake and receiving gifts.",
          birthdayGoldPawtieTitle: "Seasonal Pawtie - $60",
          birthdayGoldPawtieDescription:
            "Your pet will come to participate in a birthday party for 4 hours, eat cake, be groomed and go home with gifts and scents.",
          meetGreetTitle: "Meet & Greet - $40",
          meetGreetSubTitle: "8 Hour Evaluation",
          meetGreetDescription: `Introducing Our Meet and Greet Service!We know every dog is unique, and their social skills are key to a happy boarding experience. Our Meet and Greet Service is essential for preparing your pup for a seamless stay. Completion of this service is crucial; failure to complete it may result in the cancellation of boarding services to ensure the best experience for all dogs.
                Tailored Socialization: We organize playdates with friendly, compatible dogs, considering your pup's temperament and energy for optimal socialization.
                Social Skills Assessment: Our trained staff observes your dog's interactions to assess their comfort and adaptability for a positive boarding experience.
                Positive Reinforcement: We use positive reinforcement to encourage and reward good social behaviors, fostering new friendships.
                Continuous Progress Tracking: We track your dog's progress throughout the sessions to ensure steady improvement in their social skills.`,
          catHotel: "Cat Hotel - $30",
          catHotelPrice: "Daily Per Pet",
          catHotelDescription:
            "Welcome to Purrfect Paradise for Feline Friends!Enriching Play and Exploration: At ACBY, curious cats explore our spacious, climate-controlled playroom, stimulating their natural instincts.Playtime Galore: With climbing areas, interactive toys, and cozy hideaways, our environment offers endless entertainment. Experienced caretakers ensure joyous and stimulating playtime.Climate Comfort: Your cat enjoys air-conditioned comfort, providing respite from summer heat and cozy warmth in colder days.Always Secure: Our advanced security camera system monitors your feline friend 24/7, ensuring their safety and tracking their adventures.",
          catHotelSpecialized: "Cat Hotel Specialized - $40",
          catHotelSpecializedDescription:
            "A Special Kind of PURR. At ACBY, we cater to cats with unique health needs or age-related requirements, providing a haven of comfort, healing, and security.",
          catHotelSpecializedExpertMedicalSupervisionTitle:
            "Expert Medical Supervision",
          catHotelSpecializedExpertMedicalSupervisionDescription:
            "Our trained caregivers provide personalized medical care, administering medications, and following vet-recommended routines to ensure your cat's well-being.",
          catHotelSpecializedComfortableRetreatTitle: "Comfortable Retreat",
          catHotelSpecializedComfortableRetreatDescription:
            "Cozy, temperature-controlled rooms with plush bedding offer a soothing environment for recuperating or elderly cats.",
          catHotelSpecializedTailoredComfortCompanionshipTitle:
            "Tailored Comfort and Companionship",
          catHotelSpecializedTailoredComfortCompanionshipDescription:
            "Skilled caregivers provide medical support, gentle play, and soothing interactions to enhance your cat's emotional and physical well-being.",
          catHotelSpecializedAlwaysWatchfulTitle: "Always Watchful",
          catHotelSpecializedAlwaysWatchfulDescription:
            "With 24/7 surveillance, our advanced security system ensures your cat's safety and progress.",
          reviews: "Reviews",
          reviewsDescription: "Write a review about us by clicking here.",
          loadingReviews: "Loading Reviews...",
          outOf5Stars: "out of 5 stars", // CTA Page Translation
          ctaHeading: "ACBY",
          ctaTitle: "Our Passion",
          ctaDescription: `We are dedicated to providing the highest quality care and services for your pets. 
                Our team is comprised of experienced professionals who are passionate about animals and committed to ensuring their health and well-being.
                We offer a range of services to meet the unique needs of each pet, including hotel for pets, veterinary care, grooming, training, and more. 
                We believe that pets are an important part of the family, and we treat them with the same love, care, and respect that they deserve.
                At our animal care business, we strive to build long-lasting relationships with our clients and their pets, and we look forward to getting to know you and your furry friends.`,
          ctaButton: "Learn More",
          petHotel: "Pet Hotel",
          perksTitle: "Perks",
          perk1: "Play all day with their friends",
          perk2: "Water lovers can enjoy our pool",
          perk3: "UPDATES in real-time throughout the day",
          perk4: "Patio with artificial grass",
          perk5: "STAFF prepared for any situation",
          perk6: "Backup generator in case of a power outage",
          perk7:
            "Bulletproof glass so the pets can sleep soundly during storms or fireworks",
          perk8:
            "Opportunity to schedule grooming so they leave smelling great and with a seasonal bandana",
          checklistTitle: "Things to Prepare for Your Pet",
          checklist1: "Collar with ID tag",
          checklist2: "Leash",
          checklist3: "Food (can be portioned daily or bring the whole bag)",
          checklist4: "Preventatives",
          checklist5: "Medications if critical care is needed",
          importantLabel: "IMPORTANT",
          importantInfo:
            "We provide bowls, beds, sheets, and everything else. If an animal runs out of food before the end of their stay, we will contact the owner to find a solution. That's why we recommend packing extra portions just in case.",
          petHotelAlertDescription:
            "Before being able to stay with us in care, every pet must go through an evaluation which is our Meet & Greet. Every pet parent must create an account, get their pet's vaccinations and read the terms and conditions of our care. Any dog ​​that successfully passes the meet & greet is eligible to stay with us.",
          petHotelCat:
            "Cats do not need a Meet & Greet to stay with us. They will be in the House of Cats which provides: Tunnels, games, towers, litter, beds and a three-story condo at bedtime.  EVERY cat must arrive in a package and its food, we provide everything else.",
        },
      },
      es: {
        translation: {
          animalCareByYaraliz: "Animal Care By Yaraliz", // General Translations
          home: "Hogar",
          gallery: "Galeria",
          clear: "Borrar",
          close: "Cerrar",
          send: "Enviar",
          about: "Sobre Nosotros",
          contact: "Contacto",
          contactGinger: "Contáctenos en Gingr",
          language: "Idioma",
          services: "Servicios",
          servicesDescription: `Nuestros servicios incluyen atención veterinaria, aseo, hospedaje, capacitación y otros servicios especializados para satisfacer las necesidades de las mascotas y sus dueños.
                Estos servicios están diseñados para promover la salud y el bienestar de las mascotas, al tiempo que brindan tranquilidad y comodidad a los propietarios.`,
          pricing: "Precios",
          viewPricing: "Ver nuestros precios",
          contactUs: "Contáctenos",
          allRightsReserved: "Todos los Derechos Reservados.",
          contactInformation: "Información de contacto",
          goBackHome: "Regresar a la pagina principal",
          pageNotFound: "Página no encontrada.",
          pageNotFoundDescription:
            "Verifique la URL en la barra de direcciones y vuelva a intentarlo.",
          notificationSuccessTitle: "Éxito",
          notificationErrorTitle: "Error",
          notificationSuccessMessage:
            "El correo electrónico fue enviado exitosamente.",
          poweredBy: "Diseñado por",
          next: "Próximo",
          previous: "Previo",
          schedule: "Horario",
          checkInOut: "Hora de Entrada/Salida",
          nineToFive: "9am - 5pm",
          eightToFour: "8am - 4pm",
          nineToEleven: "9am - 11am",
          twoToFive: "2pm - 5pm",
          eightHalfToNineHalf: "8:30am - 9:30am",
          fourHalfTofiveHalf: "4:30pm - 5:30pm",
          sunday: "Domingo",
          monday: "Lunes",
          tuesday: "Martes",
          wednesday: "Miercoles",
          thursday: "Jueves",
          friday: "Viernes",
          saturday: "Sábado",
          mondayToFriday: "Lunes - Viernes",
          disclaimer: "Atención",
          noCheckInOutSchedule:
            "Hora de Entrada/Salida no está disponible fuera de este horario.",
          noCheckInOutHolidays:
            "Hora de Entrada/Salida no está disponible en días festivos.",
          homeHeaderDescription:
            "Cuidando a nuestros miembros peludos de tu familia como si fueran nuestros.", // Home Page Translations
          statsOne: "Mascotas a Diario",
          statsTwo: "Mascotas Anuales",
          statsThree: "Clientes Satisfechos",
          statsFour: "Mascotas Rescatadas",
          testimonialQuote: `“Qui dolor enim consectetur do et non ex amet culpa sint in ea non dolore. Enim minim magna anim id minim eu
                cillum sunt dolore aliquip. Amet elit laborum culpa irure incididunt adipisicing culpa amet officia
                exercitation. Eu non aute velit id velit Lorem elit anim pariatur.”`,
          testimonialAuthor: "Jane Doe",
          testimonialCareer: "CEO at StaticServed",
          availableServices: "Servicios Disponibles",
          changesACBY: "Cambios en ACBY",
          servicesOverview:
            "Para mas detalles sobre nuestros servicios, oprima aqui.",
          hotelService: "Hotel para Mascotas",
          hotelServiceDescription: `Donde tus animales de compañía podrán disfrutar de alojamiento cómodo y seguro, alimentación balanceada, atención médica de ser necesario, actividades recreativas, mucho amor y cuidado por parte de nuestro personal especializado.`,
          birthdayService: "Cumpleaños",
          birthdayServiceDescription:
            "¡En nuestra fiesta de cumpleaños para mascotas, ofrecemos decoración temática, pastel de cumpleaños especial para animales, juegos y actividades divertidas, obsequios y mucho más para que tu mascota tenga el mejor cumpleaños de su vida!",
          daycareService: "Cuido de Mascotas",
          daycareServiceDescription:
            "Ofrecemos un cuidado completo y personalizado para tu amigo peludo, desde caminatas, juegos, alimentación balanceada y atención médica de ser necesario, todo en un ambiente cómodo y seguro que le permitirá a tu mascota disfrutar de su estadía mientras tú estás ausente.",
          groomingService: "Aseo de Mascotas",
          groomingServiceDescription:
            "¡Brindamos un tratamiento de spa completo para tu peludo amigo, desde baños relajantes, cortes de pelo de moda, limpieza dental y manicure, todo en un ambiente agradable y seguro que hará que tu mascota se sienta como en casa y luzca como una estrella!",
          specializedCareService: "Atención Especializada",
          meetGreetService: "Conocer & Saludar",
          notificationErrorMessage: "Ha ocurrido un error.", // About Page Translations
          aboutDescriptionHeading: `
                ¡Bienvenido a Animal Care by Yaraliz! Somos un equipo de apasionados cuidadores de perros y otros animales dedicados a brindar el más alto nivel de cuidado para sus queridos miembros de la familia peludos. 
                Nuestra misión es asegurarnos de que sus mascotas reciban el amor y la atención que merecen mientras usted está ausente.
                `,
          aboutDescriptionOne: `
                Nuestra empresa fue fundada por Yaraliz, una cuidadora de animales experimentada y certificada con un profundo amor por todos los animales. 
                Ella cree que cada mascota tiene una personalidad única y necesita un cuidado personalizado para prosperar. 
                En Animal Care by Yaraliz, ofrecemos planes de cuidado personalizados que se adaptan a los requisitos específicos de su mascota, 
                ya sea proporcionando medicamentos, ejercicio diario o simplemente amor y atención adicionales.
                Nuestro equipo está compuesto por cuidadores de perros y otros animales experimentados y compasivos que comparten la pasión de Yaraliz por los animales. 
                Ofrecemos una amplia gama de servicios, que incluyen hospedaje de perros y otras mascotas, aseo y más. 
                Estamos disponibles las 24 horas del día, los 7 días de la semana para brindar el apoyo y el cuidado que sus mascotas necesitan, 
                y lo mantenemos informado con controles y fotos regulares.`,
          aboutDescriptionTwo: `
                En Animal Care by Yaraliz, entendemos que sus mascotas son parte de su familia, y las tratamos como tal. 
                Nos esforzamos por crear un ambiente cálido y acogedor donde sus mascotas se sientan cómodas y seguras, y donde pueda confiar en que están en buenas manos.
                Creemos que establecer una relación sólida con nuestros clientes y sus mascotas es esencial para brindar el mejor cuidado posible. 
                Nos tomamos el tiempo para conocer a cada mascota y sus necesidades únicas, y trabajamos en estrecha colaboración con usted para garantizar que cumplamos con sus expectativas.`,
          aboutDescriptionThree: `
                Gracias por considerar Animal Care by Yaraliz para las necesidades de cuidado de su mascota. 
                Esperamos conocerlo a usted y a sus queridas mascotas y brindarles el cuidado de alta calidad que merecen.
                `,
          ceo: "CEO",
          meetYaraliz: "Conoce a Yaraliz Toledo",
          photographBy: "Fotografia Por Jane Doe",
          ceoDescriptionOne: `
                Yaraliz, es la pieza clave de ACBY, es la base y la estructura de lo que hoy se conoce como Animal Care by Yaraliz. 
                Fue esa persona que cuando nadie creía en su visión ella apostó todo a ella de la mano de Kida.`,
          ceoDescriptionTwo: `
                Ella es la encargada del 99% de ACBY y es esa persona que no importa que situación tenga o que circunstancia se le presente, siempre tendrá su hermosa sonrisa y lo mejor para darle a ustedes.
                El miedo no existe en ella, es por eso que siempre trata de dar lo mejor de si misma y junto a eso inspira a otros que continuen luchando por sus sueños, así que su meta siempre es cumplida.`,
          ceoDescriptionThree: `
                Yaraliz tiene multiples preparaciones, pero sin duda su mejor preparación es la virtud que tiene para hacer sentir feliz a todo aquel que llega a ACBY, y demostrarles ese amor infinito tanto a los chicos de ACBY como a los chicos que se rescatan en las calles abandonados. 
                Ella es esa persona que no importa la hora, lugar, clima, siempre hace la acción de darle ese chance de vida a esos chicos que lo necesiten.`,
          ceoDescriptionFour: `
                ¿Que más podemos decir? 
                Todo lo que Yaraliz proyecta en cada uno de sus videos y cada una de las veces que se comunica con ustedes es simplemente esa versión avanzada de aquella niña visionaria dentro de su cuarto imaginándose todo lo hoy día está sucediendo, gracias al apoyo de todos ustedes.`,
          ceoDescriptionFive: `
                Que bien nos sentimos de que ACBY sea comandada por una persona como Yara, que su compromiso y sus ganas de echar hacia adelante junto a su equipo son lo que los mantienen preparados para cada día siempre dar la mejor versión de si mismos.
                Con mucho sudor, y con mucho esfuerzo, con ustedes: el pilar, la primera visión, el primer paso a dar, Yaraliz.`,
          ourTeam: "Nuestro Equipo",
          ourTeamDescription:
            "Somos un grupo dinámico de personas apasionadas por lo que hacemos y dedicadas a brindar el mejores resultados para nuestros clientes.",
          kidanny: "Kidanny",
          kidannyRole: "Segundo Jefe | Cuidador de Mascotas",
          kidannyDescription: `
                Cuando se habla de creatividad y mente artística, sale Kida.
                Kida es el segundo al mando en ACBY. Donde el ha ayudado desde los inicios a que Animal Care sea lo que es hoy. Desde la primera gatita de cuido hasta hoy en día.
                Es la persona detrás de los videos de INTRO en nuestros eventos, fotos e ideas. Es el encargado de los Gold Packages en los cumpleaños y tiene con el a Jan.
                Kidanny desde pequeño se ha involucrado en la música y hoy en día esta haciendo su especialidad como productor. Ha su corta edad ha logrado grandes metas como profesional y personal.`,
          jan: "Jan",
          janRole: "Cuidador de Mascotas",
          janDescription: `
                Nuestro futuro veterinario trabaja con nosotros desde hace dos años.
                Les presento a la persona que con su corta edad tiene casi 7 años de experiencia en clínica. Ha trabajado con nosotros mano a mano en el veterinario, con gatos, perros, aves, todo.
                ¡Es músico de nacimiento y pronto va a representar a PR en Italia!
                Jan trabaja con Kida en los Gold Package en nuestros cumpleaños, es el encargado de hacer la interación de cuando llegan los perritos, el que pica el bizcocho y que le gusta tener todo en orden con los regalos y los leashes.`,
          luis: "Luis",
          luisRole: "Cuidador de Mascotas Semanal y Diurno",
          luisDescription: `
                El es nuestro "Pet Sitter" de Martes a Viernes y trabaja mano a mano con Yara en los Day Cares y Pet Hotel. Todas las mañanas los chicos se vuelven locos con la llegada de el.
                La empatía y la felicidad que Luis nos da, nos llena de energía todos los días. Estamos súper contentos de poder tener en ACBY una persona capacitada, inteligente, atlético, carismático, responsable y amoroso.`,
          galleryHeading: "Para que usted vea", // Gallery page translations
          galleryDescription:
            "Nuestra maravillosa galería muestra las mascotas felices y saludables que hemos cuidado con dedicación y compasión.",
          contactHeading: "Comuniquese con nosotros", // Contact Page Translation
          contactDescription:
            "¿Alguna pregunta? Contáctese con nosotros para mas información sobre nuestros servicios.",
          summerCamp: "Campamento de Verano 2023", // Summer Camp Modal
          dogHotelSpecialTreatmentTitle:
            "Hotel para Perros | Tratamiento Especial - $50",
          dogHotelSpecialTreatmentSubTitle: "Por Mascota, Por Día",
          dogHotelSpecialTreatmentTitleDescription:
            "¡Cuidado Especial para Cachorros Únicos!",
          dogHotelSpecialTreatmentDescription: `Este servicio está diseñado para perros con necesidades específicas, asegurando que reciban la atención y el cuidado adicionales que merecen. Aquí hay algunos ejemplos de los cachorros que atendemos:`,
          dogHotelSpecialTreatmentUnneuteredDogsTitle: "Perros No Castrados",
          dogHotelSpecialTreatmentUnneuteredDogsDescription:
            "Si tu perro no está castrado, puede que necesite ser separado del grupo general por seguridad y comodidad. Proveemos un espacio acogedor y privado donde pueden relajarse y disfrutar de cuidado personalizado.",
          dogHotelSpecialTreatmentBrachycephalicDogsTitle:
            "Perros Braquicéfalos",
          dogHotelSpecialTreatmentBrachycephalicDogsDescription:
            "Razas como Bulldogs, Pugs y Boxers a menudo necesitan más descanso debido a sus narices cortas y dificultades respiratorias. Creamos un ambiente tranquilo con muchos descansos y espacios frescos para ayudarlos a mantenerse cómodos y felices.",
          dogHotelSpecialTreatmentSeniorDogsTitle: "Perros Mayores",
          dogHotelSpecialTreatmentSeniorDogsDescription:
            "Los perros mayores pueden requerir un ritmo más tranquilo y pausado. Nuestro cuidado especial incluye actividades suaves, ropa de cama suave y atención adicional para asegurarnos de que se sientan amados y seguros.",
          dogHotelSpecialTreatmentShyAnxiousDogsTitle:
            "Perros Tímidos o Ansiosos",
          dogHotelSpecialTreatmentShyAnxiousDogsDescription:
            "Algunos perros se sienten abrumados en un entorno grupal. Ofrecemos un espacio tranquilo y libre de estrés donde pueden sentirse seguros y adaptarse gradualmente a su entorno.",
          dogHotelTitle: "Hotel para Perros - $40",
          dogHotelSubTitle: "Diario por Mascota",
          dogHotelDescription: `¡Presentamos la Experiencia Definitiva de Hospedaje para Perros!`,
          dogHotelSubDescription: `En ACBY, creamos un refugio alegre y seguro para tu amigo peludo, lleno de diversión durante el día y noches acogedoras.`,
          dogHotelDaytimeDelightsTitle: "Delicias Diurnas",
          dogHotelDaytimeDelightsDescription:
            "Tu cachorro disfruta de amplios patios de juego, socialización y sol. Nuestros cuidadores capacitados aseguran un entorno seguro y atractivo.",
          dogHotelPlaytimeGaloreTitle: "Diversión sin Fin",
          dogHotelPlaytimeGaloreDescription:
            "Desde juegos de buscar hasta piscinas de chapoteo, nuestro atento personal ofrece una variedad de actividades adaptadas a las preferencias de tu perro.",
          dogHotelDreamyNightsTitle: "Noches de Ensueño",
          dogHotelDreamyNightsDescription:
            "Después de un día lleno de diversión, tu perro se relaja en nuestros cómodos dormitorios climatizados.",
          dogHotelSecurityComfortCombinedTitle:
            "Seguridad y Confort Combinados",
          dogHotelSecurityComfortCombinedDescription:
            "Nuestro sistema de seguridad de última generación con vigilancia las 24 horas asegura la seguridad de tu cachorro y te permite ver sus momentos de alegría.",
          dogHotelSweetDreamsWaggingTailsTitle:
            "Dulces Sueños y Colas Moviéndose",
          dogHotelSweetDreamsWaggingTailsDescription:
            "Únete a los dueños de mascotas felices y disfruta de viajes sin preocupaciones, sabiendo que tu cachorro está teniendo sus propias vacaciones.",
          dogHotelCriticalCareTitle:
            "Hotel para Perros | Cuidado Especializado y Crítico - $60",
          dogHotelCriticalCareSubTitle: "Un Tipo Especial de AMOR",
          dogHotelCriticalCareDescription:
            "En ACBY, brindamos un cuidado extra tierno para mascotas con necesidades de salud únicas o requisitos relacionados con la edad, asegurando comodidad, curación y seguridad.",
          dogHotelCriticalCareExpertMedicalSupervisionTitle:
            "Supervisión Médica Experta",
          dogHotelCriticalCareExpertMedicalSupervisionDescription:
            "Nuestros cuidadores capacitados, certificados en cuidados críticos y RCP para perros, brindan atención médica personalizada, administran medicamentos y siguen rutinas recomendadas por veterinarios para apoyar la salud de tu mascota.",
          dogHotelCriticalCareComfortableRetreatTitle: "Refugio Confortable",
          dogHotelCriticalCareComfortableRetreatDescription:
            "Habitaciones acogedoras y climatizadas con ropa de cama suave ofrecen un santuario calmante para mascotas en recuperación o aquellas que disfrutan de sus años dorados.",
          dogHotelCriticalCareTailoredComfortCompanionshipTitle:
            "Confort y Compañía Personalizados",
          dogHotelCriticalCareTailoredComfortCompanionshipDescription:
            "Nuestros cuidadores capacitados brindan apoyo médico, compañía, juego suave e interacciones reconfortantes para mejorar el bienestar de tu mascota.",
          dogHotelCriticalCareAlwaysWatchfulTitle: "Siempre Vigilantes",
          dogHotelCriticalCareAlwaysWatchfulDescription:
            "Con vigilancia las 24 horas, nuestro avanzado sistema de seguridad asegura la seguridad de tu mascota y nos permite monitorear su progreso.",
          joinUs: "Unete con nosotros en un campamento de verano a otro nivel!",
          summerCampContact: "Para mas informacion comunicate con nosotros!", //Services Page Translation
          petHotelTitle: "Hotel para Mascotas - $40",
          petHotelSubTitle: "Por mascota, Por día",
          petHotelDescription: `Hotel para perros y todo tipo de mascotas. Cuido desde un día o hasta los días que necesites que tu mascota se quede con nosotros mientras estás de vacaciones.
                Tu mascota estará jugando e interactuando con amigos desde que se levanta hasta que se acuesta.`,
          petSpecializedTitle: "Atención Especializada - $50",
          petSpecializedSubTitle: "Diariamente por Mascota",
          petSpecializedDescription: `Cuido de perros y todo tipo de mascotas. Si tu mascota salió de cirugía y no tienes el tiempo para cuidarla, podemos ayudarte.
                Tener un animal con medicinas diarias puede ser tedioso cuando vas de viaje.
                Yaraliz está capacitada y certificada para administrar medicamentos por vía oral, SQ, IV e IM.`,
          dayCareTitle: "Guardería - $30",
          dayCareSubTitle: "¡Desata la Aventura Definitiva para Perros!",
          dayCareDescription:
            "En ACBY, hacemos realidad el día de ensueño de cada cachorro con emoción, alegría y diversión sin fin.",
          dayCarePlayTimeExtravaganzaTitle: "Extravaganza de Juego",
          dayCarePlayTimeExtravaganzaDescription:
            "Nuestras amplias áreas de juego permiten que la energía de tu perro se libere, desde perseguir pelotas hasta correr con nuevos amigos.",
          dayCareTreatsGaloreTitle: "Delicias",
          dayCareTreatsGaloreDescription:
            "Deléita a tu cachorro con golosinas saludables que harán que su cola se mueva de alegría.",
          dayCareSplahZoneAdventureTitle: "Aventura en la Zona de Chapoteo",
          dayCareSplahZoneAdventureDescription:
            "Disfruta de juegos acuáticos refrescantes en nuestras zonas de chapoteo, manteniendo a tu perro fresco y feliz.",
          dayCareSocializationCentralTitle: "Central de Socialización",
          dayCareSocializationCentralDescription:
            "Tu perro hará amigos y se divertirá en nuestro ambiente amigable e interactivo.",
          dayCareCoolPupPopsiclesTitle: "Paletas Frías para Cachorros",
          dayCareCoolPupPopsiclesDescription:
            "Combate el calor con nuestras golosinas heladas aprobadas por perros.",
          dayCareMemoriesInTheMakingTitle: "Memorias en Creación",
          dayCareMemoriesInTheMakingDescription:
            "Captura momentos adorables con nuestros puntos de foto designados para recuerdos duraderos.",
          catHotel: "Hotel para Gatos - $30",
          catHotelPrice: "Diario por Mascota",
          catHotelDescription:
            "¡Bienvenidos al Paraíso Perfecto para Amigos Felinos! En ACBY, los gatos curiosos exploran nuestra amplia sala de juegos climatizada, estimulando sus instintos naturales. Diversión sin Fin: Con áreas para trepar, juguetes interactivos y escondites acogedores, nuestro entorno ofrece entretenimiento sin fin. Los cuidadores experimentados aseguran momentos de juego alegres y estimulantes. Confort Climático: Tu gato disfruta del confort del aire acondicionado, proporcionando un respiro del calor del verano y calidez acogedora en los días más fríos. Siempre Seguro: Nuestro avanzado sistema de cámaras de seguridad monitorea a tu amigo felino las 24 horas del día, asegurando su seguridad y seguimiento de sus aventuras.",
          catHotelSpecialized: "Hotel para Gatos Especializado - $40",
          catHotelSpecializedDescription:
            "Un Tipo Especial de RONRONEO. En ACBY, atendemos a gatos con necesidades de salud especiales o requisitos relacionados con la edad, proporcionando un refugio de comodidad, curación y seguridad.",
          catHotelSpecializedExpertMedicalSupervisionTitle:
            "Supervisión Médica Experta",
          catHotelSpecializedExpertMedicalSupervisionDescription:
            "Nuestros cuidadores capacitados brindan atención médica personalizada, administrando medicamentos y siguiendo rutinas recomendadas por veterinarios para asegurar el bienestar de tu gato.",
          catHotelSpecializedComfortableRetreatTitle: "Refugio Confortable",
          catHotelSpecializedComfortableRetreatDescription:
            "Habitaciones acogedoras y climatizadas con ropa de cama suave ofrecen un entorno relajante para gatos en recuperación o ancianos.",
          catHotelSpecializedTailoredComfortCompanionshipTitle:
            "Confort y Compañía Personalizados",
          catHotelSpecializedTailoredComfortCompanionshipDescription:
            "Cuidadores calificados brindan apoyo médico, juego suave e interacciones reconfortantes para mejorar el bienestar emocional y físico de tu gato.",
          catHotelSpecializedAlwaysWatchfulTitle: "Siempre Vigilantes",
          catHotelSpecializedAlwaysWatchfulDescription:
            "Con vigilancia las 24 horas, nuestro avanzado sistema de seguridad asegura la seguridad y el progreso de tu gato.",
          birthdayPawtieTitle: "Cumpleaños",
          birthdayBoyPawtieTitle: "Cumpleañero/Cumpleañera - $500",
          birthdayBoyPawtieDescription: `Si quieres celebrar la vida de tu perro y no tienes la creatividad,
                el momento o el lugar, nosotros nos encargamos de hacerlo realidad. Nos encargamos de la decoración, los regalos para los participantes y todo lo demás.`,
          birthdaySilverPawtieTitle: "Birthday Pawtie atendee - $75",
          birthdaySilverPawtieDescription:
            "Anotas a tu perrito en algún cumpleaños para que pase 8 horas de juego, coma bizcocho y se lleve regalos.",
          birthdayGoldPawtieTitle: "Seasonal Pawtie - $60",
          birthdayGoldPawtieDescription:
            "Tu mascota vendrá a participar en una fiesta de cumpleaños durante 4 horas, comerá pastel, será acicalada y se irá a casa con regalos y aromas.",
          meetGreetTitle: "Conocer & Saludar - $25",
          meetGreetSubTitle: "8 Horas de Evaluación",
          meetGreetDescription: `¡Presentando Nuestro Servicio de Conocer y Saludar! Sabemos que cada perro es único y sus habilidades sociales son clave para una experiencia de hospedaje feliz. Nuestro Servicio de Conocer y Saludar es esencial para preparar a tu cachorro para una estancia sin problemas. Completar este servicio es crucial; el no hacerlo puede resultar en la cancelación de los servicios de hospedaje para asegurar la mejor experiencia para todos los perros.
                Socialización Personalizada: Organizamos citas de juego con perros amigables y compatibles, considerando el temperamento y nivel de energía de tu cachorro para una óptima socialización.
                Evaluación de Habilidades Sociales: Nuestro personal capacitado observa las interacciones de tu perro para evaluar su comodidad y adaptabilidad para una experiencia de hospedaje positiva.
                Refuerzo Positivo: Utilizamos refuerzo positivo para fomentar y recompensar buenos comportamientos sociales, fomentando nuevas amistades.
                Seguimiento Continuo del Progreso: Seguimos el progreso de tu perro a lo largo de las sesiones para asegurar una mejora constante en sus habilidades sociales.
                `,
          houseOfCats: "Casa de Gatos - $30",
          houseOfCatsPrice: "Diario Por Mascota",
          houseOfCatsDescription:
            "Cuidamos de tu amigo felino libre de jaulas. Tienen un área de juegos interactivos, torres y juguetes. Proporcionamos arena para gatos y platos de comida.",
          reviews: "Reseñas",
          reviewsDescription:
            "Escribe una reseña sobre nosotros haciendo clic aquí.",
          loadingReviews: "Cargando Reseñas...",
          outOf5Stars: "de 5 estrellas", // CTA Page Translation
          ctaHeading: "ACBY",
          ctaTitle: "Nuestra Pasión",
          ctaDescription: `Estamos dedicados a brindar atención y servicios de la más alta calidad para sus mascotas.
                Nuestro equipo está compuesto por profesionales experimentados, apasionados por los animales y comprometidos con velar por su salud y bienestar.
                Ofrecemos una gama de servicios para satisfacer las necesidades únicas de cada mascota, que incluyen hospedaje para perros y otras mascotas, atención veterinaria, aseo, capacitación y más.
                Creemos que las mascotas son una parte importante de la familia y las tratamos con el mismo amor, cuidado y respeto que se merecen.
                En nuestro negocio de cuidado de animales, nos esforzamos por construir relaciones duraderas con nuestros clientes y sus mascotas, y esperamos conocerte a ti y a tus amigos peludos.`,
          ctaButton: "Leer Mas",
          petHotel: "Hotel Mascotas",
          perksTitle: "Beneficios",
          perk1: "Jugarán todo el día con sus amigos",
          perk2: "A los amantes del agua podrán disfrutar de nuestra piscina",
          perk3: "UPDATES al momento durante todo el día",
          perk4: "Patio con grama artificial",
          perk5: "STAFF preparado ante cualquier situación",
          perk6: "Planta por si se va la luz",
          perk7:
            "Cristales blindados para que los chicos puedan dormir tranquilos ante una tormenta o fuegos artificiales",
          perk8:
            "Oportunidad de agendar grooming para que se vayan olorosos y con bandana de temporada",
          checklistTitle: "Lista de cosas que debes preparar para tu mascota",
          checklist1: "Collar con ID tag",
          checklist2: "Leash",
          checklist3:
            "Comida (puede estar porcionada por día o llevar el saco)",
          checklist4: "Preventivos",
          checklist5: "Medicamentos si es cuidado crítico",
          importantLabel: "IMPORTANTE",
          importantInfo:
            "Nosotros proveemos platos, camas, sabanas y todo lo demás. Si a algún animal la comida se le acaba antes de terminar su estadía, nos contactaremos con el dueño para buscar una opción. Es por eso que recomendamos echar porciones extras por si surge alguna eventualidad.",
          petHotelAlertDescription:
            "Toda mascota antes de poder quedarse con nosotros en cuido debe pasar por una evaluación que es nuestro Meet & Greet. Todo padre de mascota debe crear una cuenta, poner sus vacunas y leer los terminos y condiciones de nuestro cuido. Todo perrito que pase con éxito el meet & greet es apto para quedarse con nosotros.",
          petHotelCat:
            "Los gatos no necesitan Meet & Greet para poder quedarse con nosotros. Ellos estaran en el House of Cats que provee: Tuneles, juegos, torres, litter, camas y un condo de tres pisos a la hora de dormir.  TODO gato debe llegar en un bulto y su comida, nosotros proveemos todo lo demás.",
        },
      },
    },
  });
