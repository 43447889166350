import React from "react";
import PetHotelSection from "../components/PetHotelSection";

export default function PetHotel() {
  return (
    <div className="bg-custom-light-orange">
      <PetHotelSection />
    </div>
  );
}
