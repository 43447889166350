import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
import Alert from "./Alert";

export default function PetHotelSection() {
  const { t } = useTranslation();

  const container = useRef(null);
  const isInView = useInView(container);
  const textAnimation = useAnimation();
  const imgAnimation = useAnimation();

  useEffect(() => {
    if (isInView) {
      textAnimation.start({
        x: 0,
        transition: {
          type: "spring",
          duration: 2,
          bounce: 0.2,
        },
      });

      imgAnimation.start({
        opacity: 1,
        transition: {
          type: "ease",
          duration: 1.5,
          ease: "easeOut",
        },
      });
    }
  }, [isInView, textAnimation, imgAnimation]);

  return (
    <div className="bg-custom-rose">
      <div className="mx-auto max-w-7xl py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <div
          ref={container}
          className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-start"
        >
          {/* Column 1: Alert on top, Image below */}
          <motion.div initial={{ opacity: 0 }} animate={imgAnimation}>
            <Alert
              title={"meetGreetService"}
              description={"petHotelAlertDescription"}
            />
            <motion.div
              initial={{ opacity: 0 }}
              animate={imgAnimation}
              className="aspect-square mt-10 overflow-hidden rounded-lg bg-gray-100"
            >
              <img
                src="/assets/img/IMG-20241010-WA0105.jpg"
                alt="Hospedaje para perros"
                className="h-full w-full object-cover shadow-lg object-center"
              />
            </motion.div>
          </motion.div>

          {/* Column 2: Text Information */}
          <motion.div initial={{ x: "100vw" }} animate={textAnimation}>
            <div className="pb-5">
              <p className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {t("petHotel")}
              </p>
            </div>

            <div className="prose prose-indigo mx-auto mt-5 text-white lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <p className="text-xl font-bold tracking-tight text-white sm:text-2xlxl">
                {t("perksTitle")}
              </p>
              <ul className="mt-4 text-sm">
                <li>{t("perk1")}</li>
                <li>{t("perk2")}</li>
                <li>{t("perk3")}</li>
                <li>{t("perk4")}</li>
                <li>{t("perk5")}</li>
                <li>{t("perk6")}</li>
                <li>{t("perk7")}</li>
                <li>{t("perk8")}</li>
              </ul>
              <p className="text-xl font-bold tracking-tight text-white sm:text-2xlxl">
                {t("checklistTitle")}
              </p>
              <ul className="mt-4 text-sm">
                <li>{t("checklist1")}</li>
                <li>{t("checklist2")}</li>
                <li>{t("checklist3")}</li>
                <li>{t("checklist4")}</li>
                <li>{t("checklist5")}</li>
              </ul>
              <p className="mt-8">
                <p>{t("petHotelCat")}</p>
                <span className="font-bold text-red-500">
                  {t("importantLabel")}:
                </span>{" "}
                {t("importantInfo")}
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
