import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function Alert({ title, description }) {
  const { t } = useTranslation();

  return (
    <div className="rounded-md bg-custom-light-green border border-custom-green p-4">
      <div className="flex flex-col items-center lg:flex-row lg:justify-between lg:items-center">
        <div className="flex items-center">
          <div className="ml-3 text-left">
            <div className="flex items-center">
              <h3 className="text-md font-bold text-white">{t(title)}</h3>
              <InformationCircleIcon className="ml-2 h-6 w-6 text-custom-green" />
            </div>
            <div className="mt-2 text-sm text-white">
              <p>{t(description)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
