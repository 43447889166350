/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    location.reload();
  };

  return (
    <Menu as="div" className="relative text-center">
      <div>
        <Menu.Button className="flex w-full justify-center items-center rounded-md bg-custom-light-orange text-sm font-medium text-gray-100 hover:custom-light-orange">
          <img
            className="h-7 w-7 object-cover rounded-full bg-gray-50 hover:border hover:border-white"
            src={`/assets/img/flags/4x3/${
              i18n.language === "es" ? "pr.svg" : "us.svg"
            }`}
            alt="Choosen language"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute border right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-custom-light-orange shadow-lg">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div className="flex items-center justify-center px-2">
                  <img
                    className="h-6 w-6 hover:border hover:border-white"
                    src="/assets/img/flags/4x3/us.svg"
                    alt="United States"
                  />
                  <a
                    onClick={() => changeLanguage("en")}
                    className={classNames(
                      active
                        ? "bg-custom-light-orange text-base font-medium text-gray-100"
                        : "text-gray-200",
                      "block px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    English
                  </a>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div className="flex items-center justify-center px-2">
                  <img
                    className="h-6 w-6 hover:border hover:border-white"
                    src="/assets/img/flags/4x3/pr.svg"
                    alt="Puerto Rico"
                  />
                  <a
                    onClick={() => changeLanguage("es")}
                    className={classNames(
                      active
                        ? "bg-custom-light-orange text-base font-medium text-white"
                        : "text-gray-200",
                      "block px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    Español
                  </a>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
